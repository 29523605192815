import React, { useState } from "react";
import { Link } from "react-router-dom";

const UserTableRow = ({ user }) => {
  return (
    <tr className="bg-[#ffffff] border-b  hover:bg-[#f3f4f6] ">
      <td className="">
        <div className="flex items-center ml-3"></div>
      </td>

      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {user.firstname}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {user.lastname}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {user.phone_number}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {user.email}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {user.completed_orders_count}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {"$" + user.total_paid_amount}
        </th>
      </td>
    </tr>
  );
};

export default UserTableRow;
