import React, { useState } from "react";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { UpdateStockAPI } from "../../apis/UpdateStockAPI";
import SuccessAlert from "../../components/alert/SuccessAlert";
const UpdateStock = () => {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [loading, setLoading] = useState(false);

  const handleFileChange = (e) => {
    if (e.target.files.length > 0) {
      setFile(e.target.files[0]);
      setFileName(e.target.files[0].name);
    }
  };

  const handleFileUpload = async () => {
    setShowSuccess(false);
    setShowError(false);
    if (!file) {
      setError({ name: ["Please select a file to upload."] });
      setShowError(true);
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      await UpdateStockAPI.update(formData).then(() => {
        setFile(null);
        setFileName("");
        setShowSuccess(true);
      });
    } catch (error) {
      console.log(error);
      setError({ name: [error.response.data.errors.file] });
      setShowError(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <ErrorAlert show={showError} error={error} />
      <SuccessAlert
        show={showSuccess}
        message={"Stock quantity updated successfully"}
      />
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen ">
          <div className="relative">
            <div className="max-w-2xl px-4 py-8 justify-center my-0 mx-auto lg:py-16 bg-white">
              <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
                Update Stock
              </h2>
              <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
                <div className="sm:col-span-2">
                  <label
                    htmlFor="file"
                    className="mb-2 block text-sm font-medium text-gray-900 "
                  >
                    Add your Excel file to update the stock
                  </label>
                  <input
                    type="file"
                    onChange={handleFileChange}
                    name="file"
                    id="file"
                    accept=".xlsx, .xls"
                    className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                    required
                  />
                  {fileName && (
                    <p className="mt-2 text-sm text-gray-600">
                      Selected file: {fileName}
                    </p>
                  )}
                </div>

                <div className="text-center w-full sm:col-span-2">
                  <button
                    onClick={handleFileUpload}
                    type="button"
                    className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4 sm:mt-6"
                  >
                    Update Stock
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UpdateStock;
