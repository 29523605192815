import React, { useState, useEffect } from "react";
import { DiscountAPI } from "../../apis/DiscountAPI";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/alert/ErrorAlert";
const AddDiscount = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [discounts, setDiscount] = useState({
    name: "",
    value: 0,
  });

  const handleInputDiscount = (e) => {
    setDiscount({ ...discounts, [e.target.name]: e.target.value });
  };

  const handleInputValue = (e) => {
    if (/^(100(\.0+)?|([0-9]{1,2}(\.[0-9]+)?))$/.test(e.target.value)) {
      setDiscount({ ...discounts, [e.target.name]: e.target.value });
    }
  };

  const apiCall = async () => {
    try {
      await DiscountAPI.create(discounts).then((response) => {
        navigate("/discounts");
      });
    } catch (error) {
      setShowError(true);
      setError(error.response.data.error);
    }
  };
  return (
    <div>
      <section className="mt-20 relative">
        <ErrorAlert show={showError} error={error} />
        <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
          <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
            Add new discount
          </h2>

          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Discount Name
              </label>
              <input
                type="text"
                value={discounts.name}
                name="name"
                id="name"
                onChange={handleInputDiscount}
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Input discount name"
                required
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Discount value
              </label>
              <input
                type="number"
                value={discounts.value}
                name="value"
                step="1"
                inputMode="decimal"
                pattern="[0-9]+([.][0-9]+)?"
                id="value"
                onChange={handleInputValue}
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Input discount value"
                required
              />
            </div>

            <div className="w-full sm:col-span-2"></div>

            <div className="text-center">
              <button
                type="submit"
                onClick={apiCall}
                className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
              >
                Add discount
              </button>
            </div>
          </div>
        </div>
      </section>
      ;
    </div>
  );
};

export default AddDiscount;
