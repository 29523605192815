import { HiInformationCircle } from "react-icons/hi";
import { Alert } from "flowbite-react";
import { useState } from "react";
import { useEffect } from "react";
function ErrorAlert({ show, error }) {
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (error !== null) setErrorMessage(getFirstErrorMessage(error));
  }, [error]);

  const getFirstErrorMessage = (errorObject) => {
    let message = "";
    for (const key in errorObject) {
      if (errorObject.hasOwnProperty(key)) {
        if (Array.isArray(errorObject[key]) && errorObject[key].length > 0) {
          message = errorObject[key][0];
          break;
        }
      }
    }

    return message;
  };
  if (!show) {
    return null;
  }

  return (
    <Alert
      color="failure"
      icon={HiInformationCircle}
      className="absolute inset-x-0 mx-auto max-w-2xl bg-red-500"
    >
      <span className="font-medium"> </span> {errorMessage}
    </Alert>
  );
}
export default ErrorAlert;
