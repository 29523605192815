import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import Layout from "./Layout/Layout";
import Home from "./pages/home/Home.js";
import NoPage from "./pages/home/NoPage.js";
import Category from "./pages/category/Categories";
import AddCategory from "./pages/category/AddCategory";
import EditCategory from "./pages/category/EditCategory";
import Type from "./pages/type/Types";
import AddType from "./pages/type/AddType";
import EditType from "./pages/type/EditType";
import Discount from "./pages/discount/Discounts";
import AddDiscount from "./pages/discount/AddDiscount";
import EditDiscount from "./pages/discount/EditDiscount";
import Color from "./pages/color/Colors";
import AddColor from "./pages/color/AddColor";
import EditColor from "./pages/color/EditColor";
import EditCountry from "./pages/country/EditCountry";
import AddCountry from "./pages/country/AddCountry";
import Country from "./pages/country/Countries";
import Product from "./pages/product/Products";
import AddProduct from "./pages/product/AddProduct";
import EditProduct from "./pages/product/EditProduct";
import Sizes from "./pages/size/Sizes";
import AddSize from "./pages/size/AddSize";
import EditSize from "./pages/size/EditSize";
import Collection from "./pages/collection/Collections";
import AddCollection from "./pages/collection/AddCollection";
import EditCollection from "./pages/collection/EditCollection";
import Themes from "./pages/theme/Themes";
import AddTheme from "./pages/theme/AddTheme";
import Item from "./pages/item/Items";
import EditTheme from "./pages/theme/EditTheme";
import Order from "./pages/order/Orders";
import OrderDetails from "./pages/order/OrdersDetails.js";
import OrderAddItem from "./pages/order/AddItem.js";
import OrderEditQuantity from "./pages/order/EditQuantity.js";
import EditVariant from "./pages/variant/EditVariant";
import AddVariant from "./pages/variant/AddVariant";
import Users from "./pages/user/Users.js";
import Login from "./pages/auth/Login.js";
import store from "./redux/store";
import PrivateRoutes from "./route/PrivateRoutes";
import ForgotPassword from "./pages/auth/ForgotPassword.js";
import Logout from "./pages/auth/Logout.js";
import ChangePassword from "./pages/auth/ChangePassword.js";
import UpdateStock from "./pages/product/UpdateStock.js";
function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/change_password/:token" element={<ChangePassword />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />

          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Layout />}>
              <Route index element={<Home />} />
              <Route path="categories/" element={<Category />} />
              <Route path="categories/add_category" element={<AddCategory />} />
              <Route
                path="categories/edit_category/:id"
                element={<EditCategory />}
              />
              <Route path="types/" element={<Type />} />
              <Route path="types/add_type" element={<AddType />} />
              <Route path="types/edit_type/:id" element={<EditType />} />
              <Route path="discounts/" element={<Discount />} />
              <Route path="discounts/add_discount" element={<AddDiscount />} />
              <Route
                path="discounts/edit_discount/:id"
                element={<EditDiscount />}
              />
              <Route path="colors/" element={<Color />} />
              <Route path="colors/add_color" element={<AddColor />} />
              <Route path="colors/edit_color/:id" element={<EditColor />} />
              <Route path="countries/" element={<Country />} />
              <Route
                path="countries/edit_country/:id"
                element={<EditCountry />}
              />
              <Route path="countries/add_country" element={<AddCountry />} />
              <Route path="products/" element={<Product />} />
              <Route path="products/add_product" element={<AddProduct />} />
              <Route path="products/update_stock" element={<UpdateStock />} />
              <Route
                path="products/edit_product/:id"
                element={<EditProduct />}
              />
              <Route path="sizes/" element={<Sizes />} />
              <Route path="sizes/add_size" element={<AddSize />} />
              <Route path="sizes/edit_size/:id" element={<EditSize />} />
              <Route path="collections/" element={<Collection />} />
              <Route
                path="collections/add_collection"
                element={<AddCollection />}
              />
              <Route
                path="collections/edit_collection/:id"
                element={<EditCollection />}
              />
              <Route path="items/:id" element={<Item />} />
              <Route path="themes/" element={<Themes />} />
              <Route path="themes/add_theme" element={<AddTheme />} />
              <Route path="themes/edit_theme/:id" element={<EditTheme />} />
              <Route path="orders/" element={<Order />} />
              <Route path="orders/:id" element={<OrderDetails />} />
              <Route path="orders/add_item/:id" element={<OrderAddItem />} />
              <Route
                path="orders/edit_item_quantity/:id"
                element={<OrderEditQuantity />}
              />
              <Route
                path="variants/edit_variant/:id"
                element={<EditVariant />}
              />
              <Route path="variants/add_variant/:id" element={<AddVariant />} />
              <Route path="users/" element={<Users />} />

              <Route path="/logout" element={<Logout />} />
              <Route path="*" element={<NoPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
