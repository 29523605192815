import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { SizeAPI } from "../../apis/SizeAPI";

const AddSize = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);

  const [sizes, setSizes] = useState({
    name: "",
  });

  const handleInputSize = (e) => {
    setSizes({ ...sizes, [e.target.name]: e.target.value });
  };

  const apiCall = async () => {
    try {
      await SizeAPI.create(sizes).then((response) => {
        navigate("/sizes");
      });
    } catch (error) {
      setShowError(true);
      setError(error.response.data.error);
    }
  };
  return (
    <div>
      <section className="mt-20 relative">
        <ErrorAlert show={showError} error={error} />
        <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
          <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
            Add new size
          </h2>

          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Size Name
              </label>
              <input
                type="text"
                value={sizes.name}
                name="name"
                id="name"
                onChange={handleInputSize}
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Input size"
                required
              />
            </div>

            <div className="text-center sm:col-span-2">
              <button
                type="submit"
                onClick={apiCall}
                className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
              >
                Add size
              </button>
            </div>
          </div>
        </div>
      </section>
      ;
    </div>
  );
};

export default AddSize;
