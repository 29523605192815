import { api } from "./configs/axiosConfigs";
import { defineCancelApiObject } from "./configs/axiosUtils";

export const UsersAPI = {
  getAll: async function (page, params = {}, cancel = false) {
    let url = `/api/admin/users/infos?page=${page}`;
    if (params.search !== undefined && params.search !== null) {
      url += `&search=${params.search}`;
    }
    const response = await api.request({
      url: url,
      method: "GET",
      signal: cancel
        ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal
        : undefined,
    });
    return response.data;
  },
};

const cancelApiObject = defineCancelApiObject(UsersAPI);
