import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ItemAPI } from "../../apis/ItemAPI";
import imageNotFound from "../../assets/images/nope-not-here.webp";
import { ColorAPI } from "../../apis/ColorAPI";
import uploadIcon from "../../assets/logo/upload-svgrepo-com.svg";
import deleteIcon from "../../assets/logo/delete-svgrepo-com.svg";
import VariantInputList from "../../components/tableRow/VariantInputList";
import { API_URL_IMAGE_PRODUCT } from "../../apis/URL/constants";
import addIcon from "../../assets/logo/add-circle-svgrepo-com.svg";
import minusIcon from "../../assets/logo/minus-circle-svgrepo-com.svg";
import { VariantAPI } from "../../apis/VariantAPI";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { SizeAPI } from "../../apis/SizeAPI";
const AddVariant = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formCount, setFormCount] = useState(0);
  const [allValues, setAllValues] = useState([]);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [inputs, setInputs] = useState({
    sku: "",
    barcode: "",
    size_id: null,
    quantity: 0,
  });
  const [images, setImage] = useState([
    { name: imageNotFound },
    { name: imageNotFound },
    { name: imageNotFound },
    { name: imageNotFound },
  ]);
  const [item, setItem] = useState([]);
  const [loading, setLoading] = useState(true);
  const [colors, setColor] = useState([
    { id: null, name: "Select color", value: null },
  ]);
  const [sizes, setSize] = useState([
    { id: null, name: "Select size", value: null },
  ]);
  const [variant, setVariant] = useState(null);
  const imageArray = new Array(4).fill(null);
  const [selectedColor, setSelectedColor] = useState("FFFFFFF");

  const getImage = (images) => {
    images.forEach((image) => {
      imageArray[image.tag - 1] = {
        name: API_URL_IMAGE_PRODUCT + image.name,
        id: image.id,
        deletable: false,
      };
    });
    for (let i = 0; i < imageArray.length; i++) {
      if (imageArray[i] === null) {
        imageArray[i] = {
          name: imageNotFound,
        };
      }
    }
    setImage(imageArray);
  };

  const handleChangeInputNumber = (e) => {
    const inputValue = e.target.value;
    if (/^[0-9]+([.][0-9]+)?$/.test(inputValue)) {
      const { name, value } = e.target;
      setInputs((prevInputs) => ({
        ...prevInputs,
        [name]: value,
      }));
      handleInputChange(0, name, value);
    }
  };

  const handleInputChangeFirstList = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
    handleInputChange(0, name, value);
  };

  const openDeleteModal = (index) => {
    const updatedProducts = [...images];
    updatedProducts[index] = { ...updatedProducts[index], name: imageNotFound };
    setImage(updatedProducts);
  };
  const addForm = () => {
    setFormCount(formCount + 1);
  };
  const getColorValueFromId = (specificId) => {
    const colorObject = colors.find((color) => color.id === specificId);
    if (colorObject) {
      return colorObject.value;
    } else {
      return null;
    }
  };

  const getColorIdFromValue = (value) => {
    const colorObject = colors.find((color) => color.value === value);
    if (colorObject) {
      return colorObject.id;
    } else {
      return null;
    }
  };

  const handleDeleteVariant = () => {
    if (formCount > 0) {
      setFormCount(formCount - 1);
    }
    setAllValues((prevArray) => prevArray.slice(0, -1));
  };
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const updatedElements = [...images];
    if (event.target.name === "image1") {
      updatedElements[0] = {
        ...updatedElements[0],
        name: URL.createObjectURL(file),
        deletable: true,
      };
    } else if (event.target.name === "image2") {
      updatedElements[1] = {
        ...updatedElements[1],
        name: URL.createObjectURL(file),
        deletable: true,
      };
    } else if (event.target.name === "image3") {
      updatedElements[2] = {
        ...updatedElements[2],
        name: URL.createObjectURL(file),
        deletable: true,
      };
    } else if (event.target.name === "image4") {
      updatedElements[3] = {
        ...updatedElements[3],
        name: URL.createObjectURL(file),
        deletable: true,
      };
    }
    setImage(updatedElements);
    setVariant({ ...variant, [event.target.name]: file });
  };

  const handleInputChange = (index, name, value) => {
    const newData = [...allValues];
    newData[index] = { ...newData[index], [name]: value };
    setAllValues(newData);
  };

  const getImagesByColor = (color) => {
    console.log(color);
    console.log(item);
    const variant = item.variants.find((variant) => variant.color === color);
    console.log(variant);
    return variant ? variant.images : [];
  };

  const handleSelectedColor = (event) => {
    const colorValue = getColorValueFromId(event.target.value);
    setSelectedColor(colorValue);
    const itemImages = getImagesByColor(colorValue);

    getImage(itemImages);
    if (event.target.value == "Select color") {
      setSelectedColor("f3f4f6");
    }
  };
  const handleSelectedSize = (newSizeId) => {
    setInputs({
      ...inputs,
      size_id: newSizeId,
    });
    handleInputChange(0, "size_id", newSizeId);
  };
  useEffect(() => {
    try {
      ColorAPI.getAll().then((colorsApi) => {
        setColor((colors) => [...colors, ...colorsApi]);
      });
    } catch (error) {
      console.log(error);
    }

    try {
      SizeAPI.getAll().then((sizeApi) => {
        setSize((sizes) => [...sizes, ...sizeApi]);
      });
    } catch (error) {
      console.log(error);
    }

    try {
      ItemAPI.get(id).then((itemApi) => {
        setItem(itemApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);
  const apiCall = async () => {
    const formData = new FormData();
    allValues.forEach((variant, index) => {
      formData.append(`variants[${index}][sku]`, variant.sku);
      formData.append(`variants[${index}][barcode]`, variant.barcode);
      formData.append(`variants[${index}][size_id]`, variant.size_id);
      if (variant.quantity !== undefined) {
        formData.append(`variants[${index}][quantity]`, variant.quantity);
      } else {
        formData.append(`variants[${index}][quantity]`, 0);
      }
    });
    formData.append("product_id", id);
    formData.append("color_id", getColorIdFromValue(selectedColor));
    if (images[0] !== imageNotFound && images[0].deletable === true) {
      formData.append("image1", variant.image1);
    }
    if (images[1] !== imageNotFound && images[1].deletable === true) {
      formData.append("image2", variant.image2);
    }
    if (images[2] !== imageNotFound && images[2].deletable === true) {
      formData.append("image3", variant.image3);
    }
    if (images[3] !== imageNotFound && images[3].deletable === true) {
      formData.append("image4", variant.image4);
    }
    console.log(formData);
    try {
      await VariantAPI.multipleInsertion(formData).then(() => {
        navigate("/items/" + id);
      });
    } catch (error) {
      console.log(error);
      setShowError(true);
      if (error.response.data.error !== undefined) {
        setError(error.response.data.error);
      } else {
        setError({ name: [error.response.data.message] });
      }
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <div className="relative">
          <ErrorAlert show={showError} error={error} />
          <div className="px-4 py-8 lg:py-5 mt-10 mb-2 md:ml-10 md:mr-10 relative shadow-md sm:rounded-lg ">
            <div className="mb-2">
              <div className="flex justify-between flex-wrap sm:flex-nowrap">
                <div className="justify-start">
                  <div className="mt-2 mb-2 w-full ">
                    <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
                      Add new variant
                    </h2>
                  </div>
                  <div className="relative w-full  lg:max-w-sm">
                    <label
                      className="block mb-2 text-sm font-medium text-gray-900"
                      htmlFor="user_avatar"
                    >
                      Select color
                    </label>
                    <div className="flex items-center justify-between">
                      <select
                        onChange={handleSelectedColor}
                        className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                      >
                        {colors &&
                          colors.length > 0 &&
                          colors.map((color) => (
                            <option key={color.id} value={color.id}>
                              {color.name}
                            </option>
                          ))}
                      </select>

                      <div
                        className="w-16 h-8 ml-4"
                        style={{ backgroundColor: "#" + selectedColor }}
                      ></div>
                    </div>
                  </div>
                </div>
                <div className="mt-2 mb-2 w-full sm:w-1/2 flex justify-end">
                  <div className="sm:col-span-2 flex justify-center mt-5">
                    <div className="flex flex-wrap sm:flex-nowrap justify-center relative">
                      <div className="mt-2 mb-5 mr-2 w-full sm:w-1/2 flex justify-center relative">
                        <img
                          className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                          alt="Image placeholder"
                          src={images[0].name}
                        />
                        {images[0].name === imageNotFound ? (
                          <div>
                            <label htmlFor="image1" className="cursor-pointer">
                              <img
                                src={uploadIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="Upload Icon"
                              />
                            </label>
                            <input
                              type="file"
                              id="image1"
                              className="hidden"
                              name="image1"
                              onChange={handleImageUpload}
                            />
                          </div>
                        ) : images[0].deletable === true ? (
                          <div>
                            <label htmlFor="image1" className="cursor-pointer">
                              <img
                                onClick={() => {
                                  openDeleteModal(0);
                                }}
                                src={deleteIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="delete"
                              />
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-2 mb-5 mr-2 w-full sm:w-1/2 flex justify-center relative">
                        <img
                          className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                          alt="Image placeholder"
                          src={images[1].name}
                        />
                        {images[1].name === imageNotFound ? (
                          <div>
                            <label htmlFor="image2" className="cursor-pointer">
                              <img
                                src={uploadIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="Upload Icon"
                              />
                            </label>
                            <input
                              type="file"
                              id="image2"
                              className="hidden"
                              name="image2"
                              onChange={handleImageUpload}
                            />
                          </div>
                        ) : images[1].deletable === true ? (
                          <div>
                            <label htmlFor="image2" className="cursor-pointer">
                              <img
                                onClick={() => {
                                  openDeleteModal(1);
                                }}
                                src={deleteIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="delete"
                              />
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-2 mb-5 mr-2 w-full sm:w-1/2 flex justify-center relative">
                        <img
                          className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                          alt="Image placeholder"
                          src={images[2].name}
                        />
                        {images[2].name === imageNotFound ? (
                          <div>
                            <label htmlFor="image3" className="cursor-pointer">
                              <img
                                src={uploadIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="Upload Icon"
                              />
                            </label>
                            <input
                              type="file"
                              id="image3"
                              className="hidden"
                              name="image3"
                              onChange={handleImageUpload}
                            />
                          </div>
                        ) : images[2].deletable === true ? (
                          <div>
                            <label htmlFor="image3" className="cursor-pointer">
                              <img
                                onClick={() => {
                                  openDeleteModal(2);
                                }}
                                src={deleteIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="delete"
                              />
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="mt-2 mr-2 mb-5 w-full sm:w-1/2 flex justify-center relative">
                        <img
                          className="relative mr-2 mb-2 sm:mr-2 sm:mb-0 inline-block h-36 w-36 rounded-2xl object-fill object-center transition-opacity duration-300"
                          alt="Image placeholder"
                          src={images[3].name}
                        />
                        {images[3].name === imageNotFound ? (
                          <div>
                            <label htmlFor="image4" className="cursor-pointer">
                              <img
                                src={uploadIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="Upload Icon"
                              />
                            </label>
                            <input
                              type="file"
                              id="image4"
                              className="hidden"
                              name="image4"
                              onChange={handleImageUpload}
                            />
                          </div>
                        ) : images[3].deletable === true ? (
                          <div>
                            <label htmlFor="image4" className="cursor-pointer">
                              <img
                                onClick={() => {
                                  openDeleteModal(3);
                                }}
                                src={deleteIcon}
                                className="w-16 h-16 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 opacity-0 transition-opacity duration-300 hover:opacity-100"
                                alt="delete"
                              />
                            </label>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <div className="lg:py-5 mt-10 mb-2 relative">
              <img
                src={minusIcon}
                className="w-10 h-10 text-gray-500"
                onClick={handleDeleteVariant}
              />
            </div>
            <div className=" px-4 py-8 lg:py-5 mt-10 mb-2 md:ml-10 md:mr-10 relative shadow-md sm:rounded-lg sm:w-1/2 grid grid-cols-1 sm:grid-cols-4 gap-4">
              <div className="flex flex-col">
                <input
                  id="input1"
                  type="text"
                  name="sku"
                  className="px-4 py-2 border rounded-md"
                  placeholder="SKU"
                  value={inputs.sku}
                  onChange={handleInputChangeFirstList}
                />
              </div>
              <div className="flex flex-col">
                <input
                  id="input2"
                  type="text"
                  name="barcode"
                  className="px-4 py-2 border rounded-md"
                  placeholder="Barcode"
                  value={inputs.barcode}
                  onChange={handleInputChangeFirstList}
                />
              </div>
              <div className="flex flex-col">
                <select
                  onChange={(event) => handleSelectedSize(event.target.value)}
                  className="w-full px-4 py-2 border rounded-md text-gray-500 bg-white shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {sizes &&
                    sizes.length > 0 &&
                    sizes.map((size) => (
                      <option key={size.id} value={size.id}>
                        {size.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex flex-col">
                <input
                  id="input4"
                  type="number"
                  name="quantity"
                  step="1"
                  inputMode="decimal"
                  pattern="[0-9]+([.][0-9]+)?"
                  value={inputs.quantity}
                  className="px-4 py-2 border rounded-md"
                  placeholder="Quantity"
                  onChange={handleChangeInputNumber}
                />
              </div>
            </div>

            <div className="lg:py-5 mt-10 mb-2 relative">
              <img
                src={addIcon}
                className="w-10 h-10 text-gray-500"
                onClick={addForm}
              />
            </div>
          </div>
          {Array.from({ length: formCount }, (_, index) => (
            <VariantInputList
              key={index}
              onAddVariant={handleInputChange}
              index={index}
              sizes={sizes}
              onDeleteVariant={() => handleDeleteVariant(index)}
            />
          ))}

          <div className="text-center w-full sm:col-span-2">
            <button
              onClick={apiCall}
              type="submit"
              className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
            >
              Add variant
            </button>
          </div>
        </div>
      )}
      ;
    </div>
  );
};

export default AddVariant;
