import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ErrorAlert from "../../components/alert/ErrorAlert";
import CartItem from "../../components/order/CartItem";
import { OrderAPI } from "../../apis/OrderAPI";
import { VariantAPI } from "../../apis/VariantAPI";
import { API_URL_IMAGE_PRODUCT } from "../../apis/URL/constants";
const AddItem = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [variant, setVariant] = useState({
    barcode: "barcode",
    sku: "sku",
    size: "size",
    quantity: 1,
    product: {
      name: "product name",
      price: "1",
    },
    color: {
      name: "color",
    },
    discount: null,
    images: [
      {
        name: "image",
      },
    ],
  });
  const [item, setItem] = useState({
    id: null,
    quantity: 0,
  });

  const handleInputId = (e) => {
    setItem({ ...item, id: e.target.value });
    if (e.target.value.length === 36) {
      getVariant(e.target.value);
    }
  };

  const handleInputValue = (e) => {
    if (/^(100(\.0+)?|([0-9]{1,2}(\.[0-9]+)?))$/.test(e.target.value)) {
      setItem({ ...item, quantity: e.target.value });
    }
  };

  const getVariant = async (id) => {
    try {
      VariantAPI.get(id).then((variant) => {
        console.log(variant);
        const data = variant.variant;
        setVariant(data);
        console.log(data);
      });
    } catch (error) {
      console.log(error.response.data);
    }
  };

  const apiCall = async () => {
    const formData = new FormData();
    formData.append("_method", "put");
    formData.append("item[id]", item.id);
    formData.append("item[quantity]", item.quantity);
    try {
      await OrderAPI.update(formData, id).then((response) => {
        navigate("/orders/" + id);
      });
    } catch (error) {
      setShowError(true);
      //   setError(error.response.data.error);
      setError({ name: [error.response.data.error] });
    }
  };
  return (
    <div>
      <section className="mt-20 relative">
        <ErrorAlert show={showError} error={error} />
        <div className="rounded-xl mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
          <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
            Add new item to order
          </h2>

          <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
            <div className="sm:col-span-2">
              <CartItem
                discount={variant.discount}
                price={variant.product.price}
                quantity={variant.quantity}
                imageUrl={API_URL_IMAGE_PRODUCT + variant.images[0].name}
                productName={variant.product.name}
                size={variant.size}
                sku={variant.sku}
                color={variant.color.name}
              />
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Variant Id
              </label>
              <input
                type="text"
                value={item.id}
                name="name"
                id="name"
                onChange={handleInputId}
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Input variant id"
                required
              />
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="name"
                className="mb-2 block text-sm font-medium text-gray-900 "
              >
                Quantity
              </label>
              <input
                type="number"
                value={item.quantity}
                name="quantity"
                step="1"
                inputMode="decimal"
                pattern="[0-9]+([.][0-9]+)?"
                id="value"
                onChange={handleInputValue}
                className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                placeholder="Input discount value"
                required
              />
            </div>

            <div className="w-full sm:col-span-2"></div>

            <div className="text-center">
              <button
                type="submit"
                onClick={apiCall}
                className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
              >
                Add item
              </button>
            </div>
          </div>
        </div>
      </section>
      ;
    </div>
  );
};

export default AddItem;
