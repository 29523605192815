import React, { useEffect, useState } from "react";
import OmLogo from "../../assets/images/om-logo.png";
import { AuthAPI } from "../../apis/AuthAPI";
import { useDispatch } from "react-redux";
import { setToken } from "../../redux/actions/authActions";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { Link } from "react-router-dom";
const ForgotPassword = () => {
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const handleChangeInputEmail = (event) => {
    setEmail(event.target.value);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    if (token) {
      navigate("/");
    }
    try {
      AuthAPI.csrfToken().then((response) => {});
    } catch (error) {
      console.log(error);
    }
  }, []);
  useEffect(() => {
    if (loading === true) {
      handleLogin();
    }
  }, [loading]);

  const handleLogin = async () => {
    try {
      await AuthAPI.forgotPassword(email).then((response) => {
        navigate("/");
      });
    } catch (error) {
      setShowError(true);
      if (error.response.data.error.email !== undefined) {
        setError({ name: [error.response.data.error.email[0]] });
      } else {
        setError({ name: [error.response.data.error] });
      }
    }
    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-wrap items-center justify-center">
        <div className="top-10 g-6 relative w-full md:w-1/2 lg:w-1/3">
          <ErrorAlert show={showError} error={error} />
        </div>
      </div>
      <div className="flex items-center justify-center h-screen w-full px-5 sm:px-0">
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
          </div>
        ) : (
          <div className="flex bg-slate-50 rounded-lg shadow-lg border overflow-hidden max-w-sm lg:max-w-4xl w-full">
            <div className="hidden md:block lg:w-1/2 bg-cover bg-slate-50 content-center justify-center">
              <img
                src={OmLogo}
                width={"100%"}
                className="items-center justify-center pl-5 pr-5"
              />
            </div>
            <div className="w-full p-8 lg:w-1/2">
              <p className="text-xl text-gray-600 text-center">
                Reset Password
              </p>
              <div className="mt-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Email Address
                </label>
                <input
                  onChange={handleChangeInputEmail}
                  className="text-gray-700 border border-gray-300 rounded py-2 px-4 block w-full focus:outline-2 focus:outline-blue-700"
                  type="email"
                  placeholder="Email"
                  required
                />
              </div>

              <div className="mt-8">
                <button
                  onClick={() => setLoading(true)}
                  className="bg-blue-700 text-white font-bold py-2 px-4 w-full rounded hover:bg-blue-600"
                >
                  Send Link
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default ForgotPassword;
