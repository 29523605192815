import React, { useEffect, useState } from "react";
import { ProductAPI } from "../../apis/ProductAPI";
import { DiscountAPI } from "../../apis/DiscountAPI";
import { CollectionAPI } from "../../apis/CollectionAPI";
import { TypeAPI } from "../../apis/TypeAPI";
import { useNavigate } from "react-router-dom";
import { CategoryAPI } from "../../apis/CategoryAPI";
import ErrorAlert from "../../components/alert/ErrorAlert";
import { ThemeAPI } from "../../apis/ThemeAPI";
const AddProduct = () => {
  const navigate = useNavigate();
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState([]);
  const [product, setProduct] = useState({
    name: "",
    display: false,
    discount_id: null,
    type_id: null,
    collection_id: null,
    description: "",
    price: 0,
    category_id: null,
    theme_id: null,
  });
  const [types, setType] = useState([]);
  const [collections, setCollection] = useState([]);
  const [themes, setTheme] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discounts, setDiscount] = useState([
    { id: null, name: "none", value: null },
  ]);
  const [categories, setCategory] = useState([
    { id: 1, name: "Select Category" },
  ]);

  const handleInputProduct = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleChangeInputNumber = (event) => {
    const inputValue = event.target.value;

    if (/^[0-9]+([.][0-9]+)?$/.test(inputValue)) {
      setProduct({ ...product, price: inputValue });
    }
  };

  useEffect(() => {
    try {
      CategoryAPI.getAll().then((categoriesApi) => {
        setCategory((categories) => [...categories, ...categoriesApi]);
      });
    } catch (error) {
      console.log(error);
    }
    try {
      DiscountAPI.getAll().then((discountsApi) => {
        setDiscount((discounts) => [...discounts, ...discountsApi]);
      });
    } catch (error) {
      console.log(error);
    }
    try {
      CollectionAPI.getAll().then((collectionsApi) => {
        setCollection(collectionsApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
    try {
      ThemeAPI.getAll().then((ThemeApi) => {
        setTheme(ThemeApi);
        setLoading(false);
      });
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    if (product.category_id !== null) {
      if (product.category_id != 1) {
        try {
          TypeAPI.getByCategory(product.category_id).then((typesApi) => {
            setType(typesApi.data);
            if (typesApi.data[0] !== undefined) {
              setProduct({ ...product, type_id: typesApi.data[0].id });
            }
          });
        } catch (error) {
          console.log(error);
        }
      } else {
        setType([]);
      }
    }
  }, [product.category_id]);

  const apiCall = async () => {
    if (product.discount_id === "none") {
      product.discount_id = null;
    }
    if (product.type_id == null && types.length > 0) {
      product.type_id = types[0].id;
    }
    if (product.collection_id == null && collections.length > 0) {
      product.collection_id = collections[0].id;
    }
    if (product.theme_id == null && themes.length > 0) {
      product.theme_id = themes[0].id;
    }
    try {
      await ProductAPI.create(product).then((response) => {
        navigate("/products");
      });
    } catch (error) {
      setShowError(true);
      setError(error.response.data.error);
    }
  };
  return (
    <div>
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-gray-900"></div>
        </div>
      ) : (
        <section className="relative">
          <ErrorAlert show={showError} error={error} />
          <div className="mx-auto max-w-2xl px-4 py-8 lg:py-16 bg-white">
            <h2 className="mb-4 text-xl font-bold justify-center text-gray-900 ">
              Add a new Product
            </h2>

            <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
              <div className="sm:col-span-2">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Product Name
                </label>
                <input
                  type="text"
                  onChange={handleInputProduct}
                  value={product.name}
                  name="name"
                  id="name"
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900   "
                  placeholder="Product name"
                  required
                />
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Category
                </label>
                <select
                  value={product.category_id}
                  onChange={(event) =>
                    setProduct({ ...product, category_id: event.target.value })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {categories &&
                    categories.length > 0 &&
                    categories.map((category) => (
                      <option key={category.id} value={category.id}>
                        {category.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Type
                </label>
                <select
                  onChange={(event) =>
                    setProduct({ ...product, type_id: event.target.value })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {types &&
                    types.length > 0 &&
                    types.map((type) => (
                      <option key={type.id} value={type.id}>
                        {type.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Collection
                </label>
                <select
                  onChange={(event) =>
                    setProduct({
                      ...product,
                      collection_id: event.target.value,
                    })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {collections &&
                    collections.length > 0 &&
                    collections.map((collection) => (
                      <option key={collection.id} value={collection.id}>
                        {collection.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Theme
                </label>
                <select
                  onChange={(event) =>
                    setProduct({
                      ...product,
                      theme_id: event.target.value,
                    })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {themes &&
                    themes.length > 0 &&
                    themes.map((theme) => (
                      <option key={theme.id} value={theme.id}>
                        {theme.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  htmlFor="name"
                  className="mb-2 block text-sm font-medium text-gray-900 "
                >
                  Price
                </label>
                <input
                  type="number"
                  onChange={handleChangeInputNumber}
                  value={product.price}
                  step="0.1"
                  pattern="[0-9]+([.][0-9]+)?"
                  inputMode="decimal"
                  name="name"
                  id="name"
                  className="focus:ring-primary-600 focus:border-primary-600  block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                  placeholder="Type product price"
                  required
                />
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Discount
                </label>
                <select
                  onChange={(event) =>
                    setProduct({ ...product, discount_id: event.target.value })
                  }
                  className="w-full p-2.5 text-gray-500 bg-white border rounded-md shadow-sm outline-none appearance-none focus:border-indigo-600"
                >
                  {discounts &&
                    discounts.length > 0 &&
                    discounts.map((discount) => (
                      <option key={discount.id} value={discount.id}>
                        {discount.name}
                      </option>
                    ))}
                </select>
              </div>

              <div className="w-full sm:col-span-2">
                <textarea
                  onChange={handleInputProduct}
                  value={product.description}
                  name="description"
                  id="description"
                  className="focus:ring-primary-600 focus:border-primary-600 block w-full rounded-lg border border-gray-300 bg-gray-50 p-2.5 text-sm text-gray-900"
                  placeholder="Product description"
                  required
                />
              </div>

              <div className="relative w-full lg:max-w-sm">
                <label
                  className="block mb-2 text-sm font-medium text-gray-900"
                  htmlFor="user_avatar"
                >
                  Display
                </label>
                <label className="relative mt-2 inline-flex items-center mb-4 cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={product.display}
                    onChange={() =>
                      setProduct({ ...product, display: !product.display })
                    }
                  />
                  <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
                </label>{" "}
              </div>

              {/* <div className="w-full"> */}

              <div className="text-center w-full sm:col-span-2">
                <button
                  onClick={apiCall}
                  type="submit"
                  className="bg-gray-900 focus:ring-white hover:bg-primary-800 mt-4 inline-flex items-center rounded-lg px-5 py-2.5 text-center text-sm font-medium text-white focus:ring-4  sm:mt-6"
                >
                  Add product
                </button>
              </div>
            </div>
          </div>
        </section>
      )}
      ;
    </div>
  );
};

export default AddProduct;
