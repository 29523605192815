import React, { useState } from "react";
import { Link } from "react-router-dom";

const OrderTableRow = ({ order, deleteModal }) => {
  let statusColorClass = "";
  if (order.status === "Cancelled" || order.status === "Refunded") {
    statusColorClass = "bg-red-600";
  } else if (order.status === "Completed") {
    statusColorClass = "bg-green-500";
  } else {
    statusColorClass = "bg-orange-400";
  }
  let paymentColorClass = "";
  if (order.paid === true) {
    paymentColorClass = "bg-green-500";
  } else {
    paymentColorClass = "bg-red-600";
  }

  return (
    <tr className="bg-[#ffffff] border-b  hover:bg-[#f3f4f6] ">
      <td className="">
        <div className="flex items-center ml-3"></div>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {order.id}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {order.customer_fullname}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {order.phone_number}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {order.user_id !== null ? "Account" : "Guest"}
        </th>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {"$" + order.total}
        </th>
      </td>

      {/* <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          <div class="font-extrabold ">
            <span class="bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500">
              {order.status}
            </span>
          </div>
        </th>
      </td> */}
      <td className="w-full lg:w-auto  text-gray-800  block lg:table-cell relative lg:static">
        <span
          className={`rounded md:py-3 px-3 text-xs  font-bold ${statusColorClass}`}
        >
          {order.status}
        </span>
      </td>
      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {order.items_number}
        </th>
      </td>

      <td>
        <th
          scope="row"
          className="px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white"
        >
          {order.date}
        </th>
      </td>
      {/* <td className={` ${order.paid ? "bg-green-500" : "bg-red-600"}`}>
        <th
          scope="row"
          className={`px-3 py-3 font-medium text-black whitespace-nowrap dark:text-white`}
        >
        </th>
      </td> */}

      <td className="w-full lg:w-auto  text-gray-800  block lg:table-cell relative lg:static">
        <span
          className={`rounded md:py-3 px-3 text-xs  font-bold ${paymentColorClass}`}
        >
          {order.paid ? "Paid" : "Unpaid"}
        </span>
      </td>

      <td className="flex px-3 py-3">
        <Link to={"/orders/" + order.id}>
          <svg
            className="h-6 w-6 mr-2 cursor-pointer"
            aria-hidden="true"
            fill="none"
            stroke="currentColor"
            strokeWidth={1.5}
            viewBox="0 0 24 24"
          >
            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
            <circle cx="12" cy="12" r="3"></circle>
          </svg>
        </Link>

        {/* <svg
          onClick={() => {
            deleteModal(true, order.id);
          }}
          className="h-6 w-6 ml-2 cursor-pointer"
          aria-hidden="true"
          fill="none"
          stroke="currentColor"
          strokeWidth={1.5}
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg> */}
      </td>
    </tr>
  );
};

export default OrderTableRow;
